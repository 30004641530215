:root {
  --font-lucky: LuckiestGuy, -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif;
}
html {
  /* background: black;
    color: white; */
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
}

main {
  height: 100vh;
  justify-content: center;
  text-align: center;
  align-items: center;
}

body.loading {
  @apply overflow-hidden cursor-wait touch-none pointer-events-none;
}

h1{
  font-size: 1.2rem;
  font-weight: 700;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes scrollImage {
  0% {
    object-position: top;
  }
  100% {
    object-position: bottom;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}
.slide-out {
  animation: slideOut 0.5s ease-in-out forwards;
}

.slide-in {
  animation: slideIn 0.5s ease-in-out forwards;
}

.slide-out-reverse {
    animation: slideOutReverse 0.5s ease-in-out backwards;
}

.slide-in-reverse {
    animation: slideInReverse 0.5s ease-in-out backwards;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutReverse {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideInReverse {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}