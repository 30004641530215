
.accordion-header {
    background-color: var(--page-theme-color); /* Use a CSS variable for dynamic color */
    transition: all 0.6s ease-in-out; /* Animate height change */
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in-out; /* Animate height change */
  }
  
  .accordion-content.open {
    max-height: 1000px; /* Adjust this value as needed for your content */
    /* overflow-y: auto; */
  }